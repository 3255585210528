import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
  Optional,
  Host,
  SkipSelf,
  Injector,
  ViewChild,
  ContentChildren,
  QueryList,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { DropDownFilterSettings, DropDownListComponent, DropDownListModule, PopupSettings } from '@progress/kendo-angular-dropdowns';

import { FloatingLabelComponent } from '@progress/kendo-angular-label';
import { CommonModule } from '@angular/common';
import { FooterTemplateDirective } from '../../directives/footer-template.directive';
import { ListGroupTemplateDirective } from '../../directives/list-group-template.directive';
import { ValueTemplateDirective } from '../../directives/value-template.directive';
import { ItemTemplateDirective } from '../../directives/item-template.directive';
import { FixedGroupTemplateDirective } from '../../directives/fixed-group-template.directive';
import { GroupTemplateDirective } from '../../directives/group-template.directive';

@Component({
  selector: 'assist-dropdownlist',
  templateUrl: './dropdownlist.component.html',
  styleUrls: ['./dropdownlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DropDownListModule, CommonModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownlistComponent),
      multi: true,
    },
  ],
})
export class DropdownlistComponent implements OnInit, ControlValueAccessor {
  @ViewChild(DropDownListComponent) kendoDropdown: DropDownListComponent;
  @ContentChild(ValueTemplateDirective, { static: true, read: TemplateRef }) valueTemplate;
  @ContentChild(ItemTemplateDirective, { static: true, read: TemplateRef }) itemTemplate;
  @ContentChildren(FixedGroupTemplateDirective, { read: TemplateRef })
  fixedGroupTemplate: QueryList<TemplateRef<any>>;
  @ContentChild(ListGroupTemplateDirective, { static: true, read: TemplateRef }) listGroupTemplate;
  @ContentChild(FooterTemplateDirective, { static: true, read: TemplateRef }) footerTemplate;
  @ContentChild(GroupTemplateDirective, { static: true, read: TemplateRef }) groupTemplate;
  @Input() filterable: boolean;
  @Input() popupSettings: PopupSettings;
  @Input() data;
  @Input() placeholder: string;
  @Input() dropdownFilter: DropDownFilterSettings;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() allowCustom: boolean;
  @Input() listHeight: number = 200;
  @Input() adaptiveMode?: 'auto' | 'none' = 'none';
  @Input() valueNormalizer: (any) => any = (x) => x;
  @Input() value: any;
  @Input() valuePrimitive: boolean;
  @Output() valueChange = new EventEmitter<any[]>();
  @Output() filterChange = new EventEmitter<string>();
  @Output() closed = new EventEmitter<any>();

  _control: AbstractControl;

  constructor(
    @Optional() public floatingLabel: FloatingLabelComponent,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private injector: Injector,
  ) {}

  onFilterFocus() {
    this.kendoDropdown.onFilterFocus();
  }

  toggle(open: boolean) {
    this.kendoDropdown.toggle(open);
  }

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);

    if (ngControl == null) {
      this._control = new FormControl<any>(this.value);
      return;
    }
    if (ngControl.control) {
      this._control = ngControl.control as FormControl<any>;
    } else {
      if (this.controlContainer) {
        this._control = (this.controlContainer.control as any).get(ngControl.name);
      } else {
        throw Error('No control found!');
      }
    }
  }

  focus() {
    if (this.floatingLabel) {
      this.floatingLabel.focused = true;
    }
  }

  blur() {
    if (this.floatingLabel) {
      this.floatingLabel.focused = false;
    }
  }
}
