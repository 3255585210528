import { createAction, props } from '@ngrx/store';
import { IdentifiedAssistEvent } from '../models';
import { OtherCalendarListItem } from '@cue/api';

export const loadReservations = createAction(
  '[Reservations] Load',
  props<{
    from: Date;
    to: Date;
    nextLink?: string;
  }>(),
);
export const loadMoreReservations = createAction('[Reservations] LoadMore');
export const setReservationsFilter = createAction(
  '[Reservations] SetFilter',
  props<{
    from: Date;
    to: Date;
    attendees: string[];
    resourceNames: string[];
    state: any;
  }>(),
);
export const refreshReservations = createAction('[Reservations] Refresh');
export const loadReservationsSuccess = createAction(
  '[Reservations] Load Success',
  props<{
    data: {
      value: { events: IdentifiedAssistEvent[]; nextLink: string };
      email: string;
    }[];
  }>(),
);

export const loadOtherCalendars = createAction('[Reservations] Load Other Calendars');
export const loadOtherCalendarsSuccess = createAction(
  '[Reservations] Load Other Calendars Success',
  props<{ otherCalendars: OtherCalendarListItem[] }>(),
);
export const loadOtherCalendarsFail = createAction('[Reservations] Load Other Calendars Fail', props<{ error: any }>());

export const deleteOtherCalendar = createAction(
  '[Reservations] Delete Other Calendar',
  props<{
    otherCalendar: OtherCalendarListItem;
  }>(),
);

export const deleteOtherCalendarSuccess = createAction(
  '[Reservations] Delete Other Calendar Success',
  props<{ otherCalendar: OtherCalendarListItem }>(),
);

export const deleteOtherCalendarFail = createAction(
  '[Reservations] Delete Other Calendar Fail',
  props<{ error: any; originalOtherCalendar: OtherCalendarListItem }>(),
);

export const addOtherCalendar = createAction(
  '[Reservations] Add Other Calendar',
  props<{
    otherCalendar: OtherCalendarListItem;
  }>(),
);

export const addOtherCalendarSuccess = createAction(
  '[Reservations] Add Other Calendar Success',
  props<{ otherCalendar: OtherCalendarListItem }>(),
);

export const addOtherCalendarFail = createAction(
  '[Reservations] Add Other Calendar Fail',
  props<{ error: any; originalOtherCalendar: OtherCalendarListItem }>(),
);

export const updateOtherCalendar = createAction(
  '[Reservations] Update Other Calendar',
  props<{
    otherCalendar: OtherCalendarListItem;
  }>(),
);

export const updateOtherCalendarSuccess = createAction(
  '[Reservations] Update Other Calendar Success',
  props<{ otherCalendar: OtherCalendarListItem }>(),
);

export const updateOtherCalendarFail = createAction(
  '[Reservations] Update Other Calendar Fail',
  props<{ error: any; originalOtherCalendar: OtherCalendarListItem }>(),
);

export const setShowPrimary = createAction('[Reservations] Set Show Primary', props<{ showPrimary: boolean }>());
export const setDisplayMode = createAction(
  '[Reservations] Set Display Mode',
  props<{
    displayMode: 'list' | 'calendar';
  }>(),
);
