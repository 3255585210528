import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';
import { allResources } from './resources.selectors';
import { getAttendeeFromLocationAndAttendees, objectSelector } from '../utils';
import { AppState, AssistEventType, Reservations, SharedState } from '../models';

export const getReservations = createSelector<AppState, SharedState, Reservations>(
  selectShared,
  (state) => state.reservations as Reservations,
);

export const reservationsSettings = objectSelector(getReservations, (_reservations: Reservations) => ({
  from: _reservations.from,
  to: _reservations.to,
  state: _reservations.state,
  resourceNames: _reservations.resourceNames,
  attendees: _reservations.attendees,
  nextLink: _reservations.nextLink,
  displayMode: _reservations.displayMode,
  otherCalendars: _reservations.otherCalendars,
}));

export const reservationsAsScheduledEvents = (email: string) =>
  createSelector(getReservations, allResources, (_reservations, _packedResources) => {
    return {
      loading: _reservations.loading,
      data: _reservations.data.sort((eventA, eventB) => eventA.start.utcDateTime.getTime() - eventB.start.utcDateTime.getTime()),
      otherCalendarData: _reservations.otherCalendarData.map((g) => ({
        email: g.email,
        data: g.events.sort((eventA, eventB) => eventA.start.utcDateTime.getTime() - eventB.start.utcDateTime.getTime()),
      })),
    };
  });

export const filteredReservations = (email: string, activeLang: string, defaultLang: string) =>
  createSelector(reservationsAsScheduledEvents(email), allResources, reservationsSettings, (s1, _resources, _settings) => {
    const data = s1.data.filter((event) => {
      const attendeeFromLocation = getAttendeeFromLocationAndAttendees(event, _resources.data, activeLang, defaultLang);
      const attendeesMails: string[] = event.attendees.map((a) => a.emailAddress.address).filter((x) => x != null);
      return (
        attendeeFromLocation != null &&
        attendeesMails.some((m) => _resources.data!.map((y) => y.email).some((f) => f.toUpperCase() === m.toUpperCase()))
      );
    });

    const otherCalendarData = s1.otherCalendarData.map((g) => {
      return {
        email: g.email,
        data: g.data.filter((event) => {
          if (event.assistEventType === AssistEventType.Limited) {
            return true;
          }
          const attendeeFromLocation = getAttendeeFromLocationAndAttendees(event, _resources.data, activeLang, defaultLang);
          const attendeesMails: string[] = event.attendees.map((a) => a.emailAddress.address).filter((x) => x != null);
          return (
            attendeeFromLocation != null &&
            attendeesMails.some((m) => _resources.data!.map((y) => y.email).some((f) => f.toUpperCase() === m.toUpperCase()))
          );
        }),
      };
    });

    return {
      data: data,
      otherCalendarData: otherCalendarData,
      loading: s1.loading || _resources.loading,
      nextLink: _settings.nextLink,
      packedResources: _resources,
    };
  });

export const otherCalendars = createSelector(getReservations, (state) => state.otherCalendars);
export const showPrimary = createSelector(getReservations, (state) => state.showPrimary);

export const displayMode = createSelector(getReservations, (state) => state.displayMode);
