import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, debounce, debounceTime, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import {
  addOtherCalendar,
  addOtherCalendarSuccess,
  deleteOtherCalendar,
  deleteOtherCalendarFail,
  deleteOtherCalendarSuccess,
  loadMoreReservations,
  loadOtherCalendars,
  loadOtherCalendarsFail,
  loadOtherCalendarsSuccess,
  loadReservations,
  loadReservationsSuccess,
  refreshReservations,
  setReservationsFilter,
  updateOtherCalendar,
  updateOtherCalendarFail,
  updateOtherCalendarSuccess,
  addOtherCalendarFail,
} from '../actions';
import { betterLatestFrom } from '../utils';
import { allResources, reservationsSettings } from '../selectors';
import { DataService } from '../services';
import { AppState } from '../models';
import { endOfDay, startOfDay } from 'date-fns';

@Injectable()
export class ReservationsEffects {
  refresh = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshReservations),
      betterLatestFrom(() => this.store.select(reservationsSettings)),
      switchMap((a) =>
        of(
          loadReservations({
            from: a[1].from,
            to: a[1].to,
            nextLink: null,
          }),
        ),
      ),
    ),
  );

  refreshWhenOtherCalendarLoadSuccess = createEffect(() => this.actions$.pipe(ofType(loadOtherCalendarsSuccess), map(refreshReservations)));
  refreshWhenOtherCalendarAddedSuccess = createEffect(() => this.actions$.pipe(ofType(addOtherCalendarSuccess), map(refreshReservations)));

  loadWhenFilterChanges = createEffect(() => this.actions$.pipe(ofType(setReservationsFilter), debounceTime(100), map(loadReservations)));

  load = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReservations),
      betterLatestFrom((_) => this.store.select(reservationsSettings)),
      switchMap((action) =>
        forkJoin([
          this.store.select(allResources).pipe(
            filter((x) => !x.loading),
            take(1),
          ),
          of(action),
        ]),
      ),
      switchMap(([_resources, [action, _reservationSettings]]) => {
        const requests: any[] = [];
        requests.push(
          this.dataService
            .getReservations(startOfDay(action.from), endOfDay(action.to), _resources.data, _reservationSettings, 'me', false)
            .pipe(
              catchError(() => {
                return of({
                  events: [],
                  nextLink: null,
                });
              }),
              map((value) => ({ value: value, email: 'me' })),
            ),
        );

        _reservationSettings.otherCalendars.data.forEach((otherCalendar) => {
          requests.push(
            this.dataService
              .getReservations(
                startOfDay(action.from),
                endOfDay(action.to),
                _resources.data,
                _reservationSettings,
                otherCalendar.email,
                otherCalendar.type === 'resource',
              )
              .pipe(
                catchError((error) => {
                  return of({
                    events: [],
                    nextLink: null,
                  });
                }),
                map((value) => ({ value: value, email: otherCalendar.email })),
              ),
          );
        });

        return forkJoin(requests);
      }),
      mergeMap((data: { value: any; email: string }[]) => {
        const arr: any[] = [
          loadReservationsSuccess({
            data: data,
          }),
        ];

        // Treshold - alespon  5 eventu
        if (data.length === 1 && data[0].value.events.length < 5 && data[0].value.nextLink) {
          arr.push(loadMoreReservations());
        }
        return arr;
      }),
    ),
  );

  loadMore = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreReservations),
      betterLatestFrom((a) => this.store.select(reservationsSettings)),
      switchMap((value) =>
        of(
          loadReservations({
            from: value[1].from,
            to: value[1].to,
            nextLink: value[1].nextLink,
          }),
        ),
      ),
    ),
  );

  loadOtherCalendars = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOtherCalendars),
      switchMap(() => this.dataService.getOtherCalendars()),
      map((otherCalendars) => loadOtherCalendarsSuccess({ otherCalendars })),
      catchError((error) => of(loadOtherCalendarsFail({ error }))),
    ),
  );

  updateOtherCalendar = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOtherCalendar),
      switchMap((action) =>
        this.dataService.updateOtherCalendar(action.otherCalendar).pipe(
          map((otherCalendar) => updateOtherCalendarSuccess({ otherCalendar })),
          catchError((error) => of(updateOtherCalendarFail({ error, originalOtherCalendar: action.otherCalendar }))),
        ),
      ),
    ),
  );

  deleteOtherCalendar = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOtherCalendar),
      switchMap((action) =>
        this.dataService.deleteOtherCalendar(action.otherCalendar).pipe(
          map(() => deleteOtherCalendarSuccess({ otherCalendar: action.otherCalendar })),
          catchError((error) => of(deleteOtherCalendarFail({ error, originalOtherCalendar: action.otherCalendar }))),
        ),
      ),
    ),
  );

  addOtherCalendar = createEffect(() =>
    this.actions$.pipe(
      ofType(addOtherCalendar),
      switchMap((action) =>
        this.dataService.addOtherCalendar(action.otherCalendar).pipe(
          map((otherCalendar) => addOtherCalendarSuccess({ otherCalendar })),
          catchError((error) => of(addOtherCalendarFail({ error, originalOtherCalendar: action.otherCalendar }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private dataService: DataService,
  ) {}
}
