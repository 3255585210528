import { ErrorHandler, Injectable } from '@angular/core';
import {
  ApiService,
  AssistResourceBM,
  addOtherCalendarRequest,
  cacheGet,
  deleteOtherCalendarRequest,
  eventStatuses,
  fromLocalized,
  otherCalendars,
  updateOtherCalendarRequest,
  searchOtherCalendarRequest,
  OtherCalendarListItem,
} from '@cue/api';
import { map, Observable, of, switchMap } from 'rxjs';
import { CalendarAvailability, CalendarEvent, CalendarScheduleItem, CalendarsService, ExtendedCalendarScheduleItem } from '@cue/calendars';
import {
  findResourceFromUniqueIdAndDisplayName,
  generateAvailability,
  getAttendeeFromLocationAndAttendees,
  getImageCoverFromResource,
  getImageUrlFromResource,
  getPatchedAvailabilityWithEvent,
  getTimeZoneFromOfficeString,
  patchAvailabilityByRestrictions,
  patchAvailabilityByWorkingHours,
  tryToFindLocation,
} from '../utils';
import { add, differenceInMinutes, getHours, getMinutes, getSeconds, isWithinInterval } from 'date-fns';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import {
  AnonymousAssistEvent,
  AppState,
  AssistEvent,
  AssistEventType,
  FaultyAssistEvent,
  FilteredResources,
  IdentifiedAssistEvent,
  isIdentifiedAssistEvent,
  LimitedAssistEvent,
  Schedule,
} from '../models';

import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AssistErrorHandler } from './error-handler';
import { utcToZonedTime } from 'date-fns-tz';
import { remove } from 'remove-accents';
import { TranslocoService } from '@ngneat/transloco';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private errorHandler: AssistErrorHandler;

  constructor(
    private translocoService: TranslocoService,
    private apiService: ApiService,
    private configService: ConfigService,
    private store: Store<AppState>,
    private authService: AuthService,
    eHandler: ErrorHandler,
    private calendarsService: CalendarsService,
  ) {
    this.errorHandler = eHandler as AssistErrorHandler;
  }

  private getCachedSchedules(filter: { fromDate?: Date; toDate?: Date; uniqueMails: any[]; filteredRooms: FilteredResources[] }) {
    return this.apiService.call(cacheGet(filter.fromDate, filter.toDate, filter.uniqueMails)).pipe(
      map((serverSchedules) => {
        return filter.filteredRooms.map((fr) => {
          return serverSchedules
            .filter((x) => (fr.resources as AssistResourceBM[]).find((res) => res.email === x.scheduleId) != null)
            .map((x: any) => {
              const originalResource = (fr.resources as AssistResourceBM[]).find((res) => res.email === x.scheduleId);
              if (x.error != null) {
                return {
                  scheduleId: x.scheduleId,
                  scheduleItems: [],
                  schedulingSettings: originalResource.schedulingSettings,
                  workingHours: originalResource!.timezone
                    ? {
                        timezone: originalResource.timezone,
                        daysOfWeek: originalResource.daysOfWeek,
                        endTime: originalResource.endTime,
                        startTime: originalResource.startTime,
                      }
                    : x.workingHours,
                  availabilityView: new Array(Math.floor(differenceInMinutes(filter.toDate!, filter.fromDate!) / 15) + 1)
                    .map((_) => CalendarAvailability.limited)
                    .join(''),
                  requirementId: fr.requirement.id,
                  additional: fr.additional,
                  resourceId: originalResource!.id,
                  serviceError: x.error?.message != null ? x.error.message : x.error?.responsiveService,
                };
              }

              return {
                ...x,
                scheduleItems: x.scheduleItems ?? [],
                schedulingSettings: originalResource.schedulingSettings,
                requirementId: fr.requirement.id,
                additional: fr.additional,
                resourceId: originalResource!.id,
              };
            });
        });
      }),
    );
  }

  private filterEventsBySettings(events: IdentifiedAssistEvent[], settings: any, resources: any[]) {
    let checker = (arr: any[], target: any[]) => target.every((v) => arr.includes(v));
    return events.filter((e) => {
      const attendeeFromLocation = getAttendeeFromLocationAndAttendees(
        e,
        resources,
        this.translocoService.getActiveLang(),
        this.translocoService.getDefaultLang(),
      );
      const accepted = attendeeFromLocation
        ? attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
          ? true
          : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
            ? false
            : null
        : null;
      const canceled = e.cancelled;

      const attendeesMails: string[] = e.attendees.map((a: any) => a.emailAddress.address).filter((x: any) => x != null);

      const states = settings.state;

      const statesFiltered =
        states.length === 4 ||
        states.length === 0 ||
        states.filter((state: any) => {
          // return   (canceled === state.value.canceled) && ( accepted ===
          // state.value.accepted);
          if (state.value.canceled === true) {
            return canceled === state.value.canceled;
          }

          if (state.value.canceled === false && canceled === false) {
            if (state.value.accepted === null) {
              return accepted === null;
            } else {
              return accepted === state.value.accepted;
            }
          } else {
            return false;
          }
        }).length > 0;

      const isResource = attendeesMails.some((m) => resources.map((x) => x.email).some((f) => f.toUpperCase() == m.toUpperCase()));

      const foundResource = resources.filter((x) => x.email.toLowerCase() == attendeeFromLocation?.emailAddress?.address.toLowerCase())[0];
      const resourceName = isResource && foundResource ? foundResource.name : attendeeFromLocation?.emailAddress.name;

      const resourceDisplayName =
        isResource && foundResource
          ? fromLocalized(foundResource.displayNameForApp, this.translocoService.getActiveLang(), this.translocoService.getDefaultLang())
          : attendeeFromLocation?.emailAddress.name;

      const stripResourceName = remove(resourceName).toLowerCase();
      const stripResourceDisplayName = resourceDisplayName != null ? remove(resourceDisplayName).toLowerCase() : null;

      const nameFiltered =
        (stripResourceName != null &&
          (settings.resourceNames.length == 0 ||
            settings.resourceNames.some((rn: string) => remove(rn).toLowerCase() === stripResourceName))) ||
        (stripResourceDisplayName != null &&
          (settings.resourceNames.length == 0 ||
            settings.resourceNames.some((rn: string) => remove(rn).toLowerCase() === stripResourceDisplayName)));
      const settingsAttendeesMails = settings.attendees.map((x: any) => x.value).filter((x: any) => x != null);
      const attendeesOrganizerFiltered =
        settings.attendees.length == 0 ||
        settings.attendees.some((m) => !m.isGroup && e.organizer?.emailAddress.address?.toUpperCase() == m.value?.toUpperCase()) ||
        checker(
          attendeesMails.map((x) => x.toUpperCase()),
          settingsAttendeesMails.map((x: string) => x.toUpperCase()),
        );

      return isResource && nameFiltered && attendeesOrganizerFiltered && statesFiltered;
    });
  }

  private onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }

  private getConfirmStatuses(requests: { email: string; iCalUid: string; id?: string }[]) {
    return this.apiService.call(eventStatuses(requests));
  }

  getTimelinePatchedScheduleForResource(
    resource: AssistResourceBM,
    fromDate: Date,
    toDate: Date,
    existingEvent?: {
      start: Date;
      end: Date;
    },
  ) {
    const availabilityView$ = this.configService.value.cachingEnabled
      ? this.apiService.call(cacheGet(fromDate, toDate, [resource.email])).pipe(
          map((x) => {
            return {
              availabilityView: x[0].availabilityView.split('') as CalendarAvailability[],
              workingHours: x[0].workingHours,
              schedulingSettings: resource.schedulingSettings,
            };
          }),
        )
      : this.calendarsService.getScheduleForEmails([resource.email], fromDate, toDate).pipe(
          map((x) => {
            return {
              availabilityView: x[0].availabilityView.split('') as CalendarAvailability[],
              workingHours: x[0].workingHours,
              schedulingSettings: resource.schedulingSettings,
            };
          }),
        );

    return availabilityView$.pipe(
      // working hours
      map((value) => {
        const workingHours = resource!.timezone
          ? {
              timezone: resource.timezone,
              daysOfWeek: resource.daysOfWeek,
              endTime: resource.endTime,
              startTime: resource.startTime,
            }
          : value.workingHours;
        return {
          resourceTimezone: resource!.timezone,
          value: patchAvailabilityByWorkingHours(
            fromDate,
            value.availabilityView,
            workingHours,
            resource!.timezone,
            value.schedulingSettings,
          ),
          settings: value.schedulingSettings,
          fromDate,
        };
      }),
      // patchnout podle restrikci
      map(({ value, settings, fromDate, resourceTimezone }) =>
        patchAvailabilityByRestrictions(resourceTimezone, fromDate, new Date(), value, settings, resource.conditions.reservedTo),
      ),
      map((value) => {
        return existingEvent != null && existingEvent != undefined
          ? getPatchedAvailabilityWithEvent(value, fromDate, existingEvent.start, existingEvent.end)
          : value;
      }),
    );
  }

  // FREE / BUSY
  getScheduleForAttendee(email: string, fromDate: Date, toDate: Date) {
    const errorView = generateAvailability(fromDate, toDate, CalendarAvailability.tentative);
    return this.calendarsService
      .getScheduleForEmails([email], fromDate, toDate)
      .pipe(map((x) => (x[0].availabilityView == null || x[0].availabilityView.length === 0 ? errorView.join('') : x[0].availabilityView)));
  }

  // FREE / BUSY
  getScheduleForAttendees(
    attendeeEmails: string[],
    fromDate: Date,
    toDate: Date,
  ): Observable<{ email: string; availabilityView: string; items: CalendarScheduleItem[] }[]> {
    const errorView = generateAvailability(fromDate, toDate, CalendarAvailability.tentative);

    if (attendeeEmails.length === 0) {
      return of([]);
    }
    return this.calendarsService.getScheduleForEmails(attendeeEmails, fromDate, toDate).pipe(
      map((x) =>
        x.map((a) => ({
          email: a.email,
          availabilityView: a.availabilityView == null || a.availabilityView.length === 0 ? errorView.join('') : a.availabilityView,
          items: a.scheduleItems,
        })),
      ),
    );
  }

  private getCalendarEventsFromIdentifiedAssistEvents(
    calendarEvents: CalendarEvent[],
    resources: AssistResourceBM[],
  ): Observable<IdentifiedAssistEvent[]> {
    return of(calendarEvents).pipe(
      map((events) => {
        if (this.configService.isGspace()) {
          events.forEach((event) => {
            const location = tryToFindLocation(
              event,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            event.locations =
              location != null
                ? [location]
                : [
                    {
                      displayName: 'N/A',
                      locationType: 'N/A',
                      locationUri: 'N/A',
                      uniqueId: 'N/A',
                      uniqueIdType: 'N/A',
                    },
                  ];
          });
        }
        return events;
      }),
      switchMap((events) => {
        const request = events
          .filter((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return attendeeFromLocatioon != null;
          })
          .map((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return {
              email: attendeeFromLocatioon.emailAddress.address,
              iCalUid: e.iCalUId,
              id: e.id,
            };
          });
        return this.getConfirmStatuses(request).pipe(map((response) => [events, response] as const));
      }),
      map(([events, response]) => {
        return events.map((e) => {
          let event: AssistEvent = null;
          try {
            if (e.iCalUId === null || e.iCalUId === '' || e.iCalUId === undefined) {
              throw new Error('Missing iCalUId, or it is empty');
            }

            const attendeeFromLocation = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            if (!attendeeFromLocation) {
              return {
                originalEvent: e,
                logged: new Date(),
                assistEventType: AssistEventType.Anonymous,
                reason: 'Cannot find event attendee from location',
              } as AnonymousAssistEvent;
            }

            const foundResponse = response.find((rE) => rE.iCalUid === e.iCalUId);
            if (!foundResponse) {
              return {
                originalEvent: e,
                logged: new Date(),
                assistEventType: AssistEventType.Anonymous,
                reason: 'No server response found',
              } as AnonymousAssistEvent;
            }

            const foundResource = findResourceFromUniqueIdAndDisplayName(
              attendeeFromLocation.emailAddress.address?.toUpperCase(),
              attendeeFromLocation.emailAddress.name?.toUpperCase(),
              resources,
            );

            if (!foundResource) {
              return {
                originalEvent: e,
                logged: new Date(),
                assistEventType: AssistEventType.Anonymous,
                reason: 'Resource for event has not been found',
              } as AnonymousAssistEvent;
            }

            const currentUserEmaIL = this.authService.getEmail();
            const currentUserUsername = this.authService.getUsername();
            const cancelMinutes = foundResource.resourceUnconfirmedEventCancelEventTime
              ? foundResource.resourceUnconfirmedEventCancelEventTime
              : 15;
            const higherBorderTime = add(e.start.utcDateTime, {
              minutes: cancelMinutes,
            });

            const beforeMinutes =
              foundResource && foundResource.resourceMinutesForConfirmationBeforeStartTime
                ? foundResource.resourceMinutesForConfirmationBeforeStartTime
                : 15;

            const lowerBorderTime = add(e.start.utcDateTime, {
              minutes: -beforeMinutes,
            });

            const accepted =
              attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                ? true
                : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                  ? false
                  : null;

            const requiresConfirmation = foundResource ? foundResource.requiresConfirmation : false;

            const timezone = getTimeZoneFromOfficeString(foundResource.timezone);
            const timeInZone = utcToZonedTime(e.start.utcDateTime, timezone);

            const isMidnight = getHours(timeInZone) === 0 && getMinutes(timeInZone) === 0 && getSeconds(timeInZone) === 0;

            const hours = Math.floor(foundResource.resourceUnconfirmedEventCancelEventAllDayTime! / 60);
            const minutes = foundResource.resourceUnconfirmedEventCancelEventAllDayTime! % 60;

            const confirmFromMidnight = isMidnight ? timeInZone <= new Date() : false;

            const showConfirmButton =
              !e.cancelled &&
              !foundResponse.canceled &&
              !foundResponse.confirmed &&
              requiresConfirmation &&
              ((isWithinInterval(new Date(), {
                start: lowerBorderTime,
                end: higherBorderTime,
              }) &&
                new Date() <= e.end.utcDateTime) ||
                confirmFromMidnight);

            const showChangeButton =
              !e.cancelled &&
              !foundResponse.canceled &&
              accepted &&
              (foundResponse.confirmed || !showConfirmButton) &&
              e.end.utcDateTime >= new Date();

            const imageUrl = getImageUrlFromResource(foundResource);

            const showFinish = !e.cancelled && !foundResponse.canceled && e.start.utcDateTime <= new Date();

            const identifiedEvent: IdentifiedAssistEvent = {
              id: e.id,
              assistEventType: AssistEventType.Identified,
              end: e.end,
              start: e.start,
              organizer: e.organizer,
              visibility: e.visibility,
              subject: e.subject,
              showAs: e.showAs,
              status: e.status,
              isAllDay: e.isAllDay,
              attendees: e.attendees,
              accepted:
                attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                  ? true
                  : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                    ? false
                    : null,
              isOrganizer: e.isOrganizer,
              bodyHtml: e.bodyHtml,
              reminder: e.reminder,
              bodyPreview: e.bodyPreview,
              locations: e.locations,
              type: e.type,
              onlineMeetingProvider: e.onlineMeetingProvider,
              seriesMasterId: e.seriesMasterId,
              eventPermissions: e.eventPermissions,
              cancelledByResponse: foundResponse.canceled,
              cancelled: foundResponse.canceled || e.cancelled,
              confirmed: foundResponse.confirmed,
              resource: foundResource,
              iCalUId: e.iCalUId,
              orderItemsCount: foundResponse.orderItemsCount,
              afterEnd: e.end.utcDateTime < new Date(),
              confirmUntil: higherBorderTime,
              showConfirmButton: showConfirmButton,
              showChangeButton: showChangeButton,
              confirmedWhen: foundResponse.confirmedWhen ? new Date(foundResponse.confirmedWhen) : null,
              confirmedBy: foundResponse.confirmedBy,
              imagePreferCover: getImageCoverFromResource(foundResource),
              imageUrl: imageUrl !== null && imageUrl !== '' ? imageUrl : '/assets/images/placeholder.jpg',
              reservationsEnabled: foundResource.reservationsEnabled,
              navigationEnabled: foundResource.navigationEnabled,
              showFinish: showFinish,
            };
            return identifiedEvent;
          } catch (error: any) {
            // Proste se to nepovedlo
            return {
              error: error?.message ?? error,
              logged: new Date(),
              assistEventType: AssistEventType.Faulty,
              originalEvent: e,
            } as FaultyAssistEvent;
          }
        });
      }),
      switchMap((y) => {
        const toLog = y.filter((e) => !isIdentifiedAssistEvent(e));
        return this.errorHandler.writeEventsLog(toLog).pipe(map(() => y.filter(isIdentifiedAssistEvent)));
      }),
    );
  }

  getSchedulesForResources(filteredRooms: FilteredResources[], fromDate: Date, toDate: Date): Observable<Schedule[][]> {
    const emails: string[] = [];
    filteredRooms.forEach((frooms) => {
      const toAdd = (frooms.resources as AssistResourceBM[]).map((x) => x.email);
      emails.push(...toAdd);
    });

    if (this.configService.value.cachingEnabled && this.configService.getMode() !== 'touchone-calendar') {
      const uniqueMails = emails.filter(this.onlyUnique);
      return this.getCachedSchedules({
        fromDate: fromDate,
        toDate: toDate,
        uniqueMails: uniqueMails,
        filteredRooms: filteredRooms,
      });
    }

    return this.calendarsService.getScheduleForEmails(emails, fromDate, toDate).pipe(
      switchMap((calendarSchedules) => {
        let statuses = of([]);
        if (this.configService.isTouchoneCalendar()) {
          const requests = calendarSchedules.reduce(
            (acc, cs) => [
              ...acc,
              ...cs.scheduleItems.map((x) => ({
                id: x.id,
                iCalUid: x.iCalUId,
                email: cs.email,
              })),
            ],
            [] as any[],
          );

          statuses = this.getConfirmStatuses(requests);
        }

        return statuses.pipe(
          map((statuses) => ({
            statuses,
            calendarSchedules,
          })),
        );
      }),
      map(({ calendarSchedules, statuses }) => {
        return filteredRooms.map((fr) => {
          return (
            calendarSchedules
              .filter((cs) => (fr.resources as AssistResourceBM[]).find((res) => res.email == cs.email) != null)
              // unique by email
              .filter((value, index, self) => self.map((x) => x.email).indexOf(value.email) === index)
              .map((cs) => {
                const originalResource = (fr.resources as AssistResourceBM[]).find((res) => res.email === cs.email);
                const data: Schedule = {
                  serviceError: cs.serviceError,
                  additional: false,
                  availabilityView: cs.availabilityView,
                  schedulingSettings: originalResource.schedulingSettings,
                  workingHours: cs.workingHours
                    ? cs.workingHours
                    : {
                        timezone: originalResource.timezone,
                        daysOfWeek: originalResource.daysOfWeek,
                        endTime: originalResource.endTime,
                        startTime: originalResource.startTime,
                      },
                  resourceId: originalResource.id,
                  scheduleId: cs.email,
                  scheduleItems: this.configService.isTouchoneCalendar()
                    ? cs.scheduleItems.map((si) => {
                        const data: any = {
                          ...si,
                          start: si.start as any,
                          end: si.end as any,
                          status: (si as ExtendedCalendarScheduleItem).showAs.key,
                        };

                        if (this.configService.isTouchoneCalendar()) {
                          const foundResponse = statuses.find((rE) => rE.iCalUid === si.iCalUId);
                          if (foundResponse) {
                            const cancelMinutes = originalResource.resourceUnconfirmedEventCancelEventTime
                              ? originalResource.resourceUnconfirmedEventCancelEventTime
                              : 15;
                            const higherBorderTime = add(new Date(si.start), {
                              minutes: cancelMinutes,
                            });

                            const beforeMinutes =
                              originalResource && originalResource.resourceMinutesForConfirmationBeforeStartTime
                                ? originalResource.resourceMinutesForConfirmationBeforeStartTime
                                : 15;

                            const lowerBorderTime = add(new Date(si.start), {
                              minutes: -beforeMinutes,
                            });
                            const timezone = getTimeZoneFromOfficeString(originalResource.timezone);
                            const timeInZone = utcToZonedTime(new Date(si.start), timezone);
                            const requiresConfirmation = originalResource ? originalResource.requiresConfirmation : false;
                            const isMidnight = getHours(timeInZone) === 0 && getMinutes(timeInZone) === 0 && getSeconds(timeInZone) === 0;
                            const confirmFromMidnight = isMidnight ? timeInZone <= new Date() : false;

                            const toConfirm =
                              !si.cancelled &&
                              !foundResponse.canceled &&
                              !foundResponse.confirmed &&
                              requiresConfirmation &&
                              ((isWithinInterval(new Date(), {
                                start: lowerBorderTime,
                                end: higherBorderTime,
                              }) &&
                                new Date() <= new Date(si.end)) ||
                                confirmFromMidnight);

                            const unconfirmed =
                              !si.cancelled &&
                              !foundResponse.canceled &&
                              !foundResponse.confirmed &&
                              requiresConfirmation &&
                              !(
                                (isWithinInterval(new Date(), {
                                  start: lowerBorderTime,
                                  end: higherBorderTime,
                                }) &&
                                  new Date() <= new Date(si.end)) ||
                                confirmFromMidnight
                              );

                            data.confirmUntil = higherBorderTime;
                            data.toConfirm = toConfirm;
                            data.unconfirmed = unconfirmed;
                            data.requiredConfirmation = requiresConfirmation;
                            (data.confirmedWhen = foundResponse.confirmedWhen ? new Date(foundResponse.confirmedWhen) : null),
                              (data.confirmedBy = foundResponse.confirmedBy);
                          }
                        }

                        return data;
                      })
                    : [],
                  requirementId: fr.requirement.id,
                };
                return data;
              })
          );
        });
      }),
    );
  }

  // eventy samotny
  getEventById(email: string, id: string, resources: AssistResourceBM[]): Observable<IdentifiedAssistEvent> {
    return this.calendarsService.getCalendarEventById(email, id).pipe(
      switchMap((event) => this.getCalendarEventsFromIdentifiedAssistEvents([event], resources)),
      map((events) => events[0]),
    );
  }

  getEventsForCollegue(identifier: string, from: Date, to: Date): Observable<IdentifiedAssistEvent[]> {
    return this.calendarsService.getCalendarEvents(from, to, identifier).pipe(
      concatLatestFrom((x) => this.store.select((x) => x.shared.resources).pipe(map((x) => x.data))),
      switchMap(([eventsResponse, resources]) => this.getCalendarEventsFromIdentifiedAssistEvents(eventsResponse.events, resources)),
    );
  }

  getDashboardEvents(resources: AssistResourceBM[]): Observable<IdentifiedAssistEvent[]> {
    const getEventsFromToNextLink = (from: Date, to: Date, nextLink?: string): Observable<CalendarEvent[]> => {
      return this.calendarsService.getCalendarEvents(from, to, 'me', { nextLink: nextLink }).pipe(
        switchMap(({ events, nextLink }) => {
          if (nextLink != null) {
            return getEventsFromToNextLink(from, to, nextLink).pipe(map((x) => [...events, ...x]));
          } else {
            return of(events);
          }
        }),
        map((events) => {
          // only distinct and top 10
          return events.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        }),
      );
    };

    return getEventsFromToNextLink(new Date(), add(new Date(), { days: 30 })).pipe(
      switchMap((events) => this.getCalendarEventsFromIdentifiedAssistEvents(events, resources)),
      map((events) => {
        return events
          .filter((e) => {
            return !e.cancelled && !e.cancelledByResponse;
          })
          .sort((a, b) => {
            return a.start.utcDateTime < b.start.utcDateTime ? -1 : 1;
          })
          .slice(0, 10);
      }),
    );
  }

  getMyEventsWhereIsResource(from: Date, to: Date, resources: AssistResourceBM[]): Observable<IdentifiedAssistEvent[]> {
    return this.calendarsService
      .getCalendarEvents(from, to, 'me')
      .pipe(switchMap((r) => this.getCalendarEventsFromIdentifiedAssistEvents(r.events, resources)));
  }

  getReservationByICalIUid(
    from: Date,
    to: Date,
    resources: AssistResourceBM[],
    settings: any,
    iCalUid: string,
  ): Observable<IdentifiedAssistEvent> {
    return this.getReservations(from, to, resources, settings, 'me', false).pipe(
      map((response) => response.events.find((x) => (x as IdentifiedAssistEvent).iCalUId == iCalUid) as IdentifiedAssistEvent),
    );
  }

  getOtherCalendars(): Observable<OtherCalendarListItem[]> {
    return this.apiService.call(otherCalendars());
  }

  updateOtherCalendar(otherCalendar: OtherCalendarListItem): Observable<OtherCalendarListItem> {
    return this.apiService.call(updateOtherCalendarRequest(otherCalendar));
  }

  searchOtherCalendar(search: string): Observable<OtherCalendarListItem[]> {
    return this.apiService.call(searchOtherCalendarRequest(search));
  }

  addOtherCalendar(otherCalendar: OtherCalendarListItem): Observable<OtherCalendarListItem> {
    return this.apiService.call(addOtherCalendarRequest(otherCalendar));
  }

  deleteOtherCalendar(otherCalendar: OtherCalendarListItem): Observable<OtherCalendarListItem> {
    return this.apiService.call(deleteOtherCalendarRequest(otherCalendar));
  }

  getReservations(
    from: Date,
    to: Date,
    resources: AssistResourceBM[],
    settings: any,
    calendarEmail: string,
    isResource: boolean,
  ): Observable<{ events: IdentifiedAssistEvent[] | LimitedAssistEvent[]; nextLink: string }> {
    return this.calendarsService.getCalendarEvents(from, to, calendarEmail, settings).pipe(
      switchMap((r) =>
        this.getCalendarEventsFromIdentifiedAssistEvents(r.events, resources).pipe(
          map((x) => ({
            events: x,
            nextLink: r.nextLink,
          })),
        ),
      ),
      map((x) => {
        return {
          events: this.filterEventsBySettings(x.events.filter(isIdentifiedAssistEvent), settings, resources),
          nextLink: x.nextLink,
        };
      }),
      catchError((error) => {
        if (isResource && error.status === 404 && this.calendarsService.getCalendarEventsAlternative) {
          return this.calendarsService.getCalendarEventsAlternative(from, to, calendarEmail, settings).pipe(
            map((x) => ({
              ...x,
              events: x.events.map((e: any) => ({ ...e, assistEventType: AssistEventType.Limited })),
            })),
          );
        } else {
          throw error;
        }
      }),
    );
  }
}
