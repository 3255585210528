import { createReducer, on } from '@ngrx/store';
import { FilterAttendee, IdentifiedAssistEvent, Pack } from '../models';
import { refreshSchedules, schedulesLoaded } from '../actions/schedules.actions';
const initialState: Pack<IdentifiedAssistEvent[]> = {
  loading: false,
  data: [],
  error: null,
};

export const myEventsReducer = createReducer(
  initialState,
  on(refreshSchedules, (state) => ({
    ...state,
    loading: true,
    data: [],
  })),
  on(schedulesLoaded, (state, { myEvents }) => ({
    ...state,
    loading: false,
    data: myEvents,
  })),
);
