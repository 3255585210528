import { Reservations } from '../models';
import { createReducer, Action, on } from '@ngrx/store';
import {
  addOtherCalendarSuccess,
  deleteOtherCalendar,
  loadOtherCalendars,
  loadOtherCalendarsFail,
  loadOtherCalendarsSuccess,
  loadReservations,
  loadReservationsSuccess,
  refreshReservations,
  setDisplayMode,
  setReservationsFilter,
  setShowPrimary,
  updateOtherCalendar,
  updateOtherCalendarFail,
} from '../actions';
import { EVENT_STATUSES } from '../utils/constants';
import { addDays, addMinutes, endOfWeek, startOfDay } from 'date-fns';

export const initialState: Reservations = {
  displayMode: 'calendar',
  loading: false,
  data: [],
  otherCalendarData: [],
  state: EVENT_STATUSES,
  from: startOfDay(new Date()),
  to: addMinutes(addDays(startOfDay(new Date()), 7), -1),
  resourceNames: [],
  attendees: [],
  otherCalendars: {
    loading: false,
    data: [],
  },
  showPrimary: true,
};

const reducer = createReducer(
  initialState,
  on(loadReservations, (state, action) => {
    return { ...state, loading: true };
  }),
  on(refreshReservations, (state, action) => {
    return { ...state, data: [], nextLink: null };
  }),
  on(setReservationsFilter, (state, action) => {
    return {
      ...state,
      from: action.from,
      to: action.to,
      resourceNames: action.resourceNames,
      state: action.state,
      attendees: action.attendees,
      nextLink: null,
      data: [],
    };
  }),
  on(loadReservationsSuccess, (state, action) => {
    return {
      ...state,
      data: [...state.data, ...action.data.find((x) => x.email === 'me').value.events],
      otherCalendarData: action.data.filter((x) => x.email !== 'me').map((x) => ({ email: x.email, events: x.value.events })),
      loading: false,
      nextLink: action.data.find((x) => x.email === 'me').value.nextLink,
    };
  }),
  on(loadOtherCalendars, (state, action) => {
    return { ...state, otherCalendars: { ...state.otherCalendars, loading: true } };
  }),
  on(loadOtherCalendarsSuccess, (state, action) => {
    return { ...state, otherCalendars: { loading: false, data: action.otherCalendars } };
  }),
  on(loadOtherCalendarsFail, (state, action) => {
    return { ...state, otherCalendars: { loading: false, data: [] } };
  }),

  on(updateOtherCalendar, (state, action) => {
    return {
      ...state,
      otherCalendars: {
        ...state.otherCalendars,
        data: state.otherCalendars.data.map((item) => (item.id === action.otherCalendar.id ? action.otherCalendar : item)),
      },
    };
  }),
  on(updateOtherCalendarFail, (state, action) => {
    return {
      ...state,
      otherCalendars: {
        ...state.otherCalendars,
        data: state.otherCalendars.data.map((item) => (item.id === action.originalOtherCalendar.id ? action.originalOtherCalendar : item)),
      },
    };
  }),

  on(addOtherCalendarSuccess, (state, action) => {
    return {
      ...state,
      otherCalendars: {
        ...state.otherCalendars,
        data: [...state.otherCalendars.data, action.otherCalendar],
      },
    };
  }),
  on(deleteOtherCalendar, (state, action) => {
    return {
      ...state,
      otherCalendars: {
        ...state.otherCalendars,
        data: state.otherCalendars.data.filter((item) => item.id !== action.otherCalendar.id),
      },
    };
  }),

  // primary set
  on(setShowPrimary, (state, action) => {
    return {
      ...state,
      showPrimary: action.showPrimary,
    };
  }),
  // dipaly mode set
  on(setDisplayMode, (state, action) => {
    return {
      ...state,
      displayMode: action.displayMode,
    };
  }),
);

export function reservationsReducer(state: Reservations, action: Action) {
  return reducer(state, action);
}
